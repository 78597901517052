import { Text, Title, Grid, Skeleton, Stack, Button, Space, Divider, Modal, Select, TextInput, SimpleGrid, Paper, Group, Tabs, Image, Pagination} from "@mantine/core";
import {
    IconArrowsLeftRight,
    IconCpu,
    IconTemperature,
    IconPropeller
} from '@tabler/icons-react';
import { useEffect, useState } from "react";

import { getTowerDetails } from "../../hooks/towers/useGetTowerDetails";
import { addDeviceRole } from "../../hooks/devices/useAddDeviceRole"
import { getTowerImage } from "../../hooks/towers/useGetTowerImage";

import ApDetail from "../devices/ApDetail";
import RoleCard from "../devices/RoleCard"
import MapWithMarker from "../maps/MapWithMarker";

import classes from "../../index.css"


export default function TowerDetail({tower_id}){

    const [refereshPage, setRefreshPage] = useState(false)
    

    const [towerLoaded, setTowerLoaded] = useState(false)
    const [towerLat, setTowerLat] = useState()
    const [towerLng, setTowerLng] = useState()
    const [towerName, setTowerName] = useState()
    const [towerRoles, setTowerRoles] = useState()


    const [towerLiveImage, setTowerLiveImage] = useState()
    const [towerLiveImageCount, setTowerLiveImageCount] = useState()
    const [paginationImageNumber, setPaginationImageNumber] = useState(1)
    const [paginationDisabled, setPaginationDisabled] = useState(false)


    const [addDeviceModalVisible, setAddDeviceModalVisible] = useState(false)
    const [addDeviceRoleLoading, setAddDeviceRoleLoading] = useState(false);
    const [addRoleName, setAddRoleName] = useState("")
    const [addRoleType, setAddRoleType] = useState("")
    const [addRoleIp, setAddRoleIp] = useState("")

    const [viewApDetail, setViewApDetail] = useState(false)
    const [viewApId, setViewApId] = useState()
    const [viewApName, setViewApName] = useState()
    const [viewApIp, setViewApIp] = useState()
    
    function returnDummyText() {
        return (
            <Text size="xs" align="center">
                    Pertinent Metric
                    </Text>
        )
    }

    let dummyData=[
        {title: 'Ping', icon: IconArrowsLeftRight, value: '00ms'},
        {title: 'CPU Usage', icon: IconCpu, value: '84%'},
        {title: 'CPU Temperature', icon: IconTemperature, value: '43° C'},
        {title: 'Cooling Fan Speeds', icon: IconPropeller, value: '5654 RPM'}
    ]


    useEffect(()=>{
        if(tower_id){
            setTowerLoaded(false);
            getTowerDetails(tower_id)
            .then((res)=>{
                if(res.status === "success"){
                var tower_info = res.data.location_info
                setTowerLat(parseFloat(tower_info.lat))
                setTowerLng(parseFloat(tower_info.lng))
                setTowerName(tower_info.name)
                if (tower_info.image && paginationImageNumber === 1){
                    setTowerLiveImage(tower_info.image)
                    setTowerLiveImageCount(tower_info.image_count)
                }
                
                setTowerLoaded(true)
                setTowerRoles(res.data.location_roles)
                }
                
            })
        }
    }, [refereshPage])

    useEffect(()=>{
        
        if(towerLoaded){
            setPaginationDisabled(true)
            getTowerImage(tower_id, paginationImageNumber)
            .then((res)=>{
                if(res.status === "success"){
                    setTowerLiveImage(res.image)
                    setPaginationDisabled(false)
                }else{
                    setPaginationDisabled(false);
                }
            })
        }

    },[paginationImageNumber])

    function openNewRoleModal(roleType){
        setAddDeviceModalVisible(true)
        setAddRoleType(roleType)
    }
    function closeNewRoleModal(){
        setAddRoleName("");
        setAddRoleType("");
        setAddRoleIp("");
        setAddDeviceModalVisible(false);
    }

    function handleSubmitNewRole(){
        setAddDeviceRoleLoading(true)
        addDeviceRole(addRoleName, addRoleType, addRoleIp, tower_id)
        .then((res)=>{
            if(res.status === "success" ){
                closeNewRoleModal()
                setAddDeviceRoleLoading(false)
                setRefreshPage(!refereshPage)
            }else{
                setAddDeviceRoleLoading(false)
                
            }
        })
    }
    
    function handleOpenApDetailModal(roleId, roleName, roleIp, roleType){
        setViewApId(roleId);
        setViewApName(roleName);
        setViewApIp(roleIp);
        setViewApDetail(true);
    }
    function handleCloseApDetailModal(){
        setViewApDetail(false);
    }

    const formatToLocalTime = (isoString) => {
        const date = new Date(isoString);
        return date.toLocaleString(); // This uses the user's local time zone
      };

    const stats = dummyData.map((stat) => {
        const Icon = stat.icon;

        return (
            <Paper shadow="sm" withBorder p="xl" radius="lg" key={stat.title}>
                <Group>
                    <Icon className={classes.icon} size="1.4rem" stroke={1.5} />
                    <Text size="xs" c="dimmed" className={classes.title}>
                        {stat.title}
                    </Text>
                </Group>

                <Group mt={75}>
                    <Text className={classes.value}>
                        {stat.value}
                    </Text>
                </Group>
            </Paper>
        );
    });

    return(
        <>  
            <Skeleton  visible={!towerLoaded}>
                <Title>{towerName}</Title>
            </Skeleton>


            <Space h={"xl"} > </Space>


            <Grid>
                <Grid.Col span={6}>
                    <Skeleton visible={!towerLoaded}>
                        <Tabs defaultValue="map">
                            <Tabs.List>
                                <Tabs.Tab value="map">Map</Tabs.Tab>
                                {towerLiveImage && <Tabs.Tab value="liveImage">Live Image</Tabs.Tab> }
                            </Tabs.List>

                            <Tabs.Panel value="map">
                                <MapWithMarker lat={towerLat} lng={towerLng} />
                            </Tabs.Panel>
                            {towerLiveImage &&
                            <>
                            <Tabs.Panel value="liveImage">

                                <Image src={`data:image/jpeg;base64, ${towerLiveImage.imageData}`} alt="live_Image"  withPlaceholder></Image>
                                <Text>{formatToLocalTime(towerLiveImage.datetime)}</Text>
                                <Pagination siblings={3} disabled={paginationDisabled} value={paginationImageNumber} onChange={setPaginationImageNumber} total={towerLiveImageCount-1} />

                            </Tabs.Panel>
                            </>}
                        </Tabs>
                        
                    </Skeleton>
                    {/* <Button onClick={()=>{setRefreshPage(!refereshPage)}}>Reload+</Button> */}
                </Grid.Col>
                <Grid.Col span={6}>
                    <Skeleton visible={!towerLoaded}>
                        <SimpleGrid cols={2}>
                            {stats}
                        </SimpleGrid>
                    </Skeleton>
                </Grid.Col>
            </Grid>


            <Space h={"xl"} > </Space>


            <Skeleton visible={!towerLoaded}>
            <Grid align="center">
                <Grid.Col span={11}>
                    <Divider label={"Core"} my={"md"} orientation={"horizontal"} variant={"solid"} size={"xs"} />
                </Grid.Col>
                <Grid.Col span={1}>
                    <Button onClick={()=>{openNewRoleModal(1)}}>+</Button>
                </Grid.Col>
            </Grid>
            <Grid align={"center"}>
            {towerRoles && towerRoles.map((item)=>{
                if(item.roleType === 1){
                    return(
                            <Grid.Col md={6} lg={3}>
                                <RoleCard  roleName={item.roleName} roleIp={item.roleIp} roleType={item.roleType}/>
                            </Grid.Col>       
                    )
                }
            })}
            </Grid>



            <Grid align="center">
                <Grid.Col span={11}>
                    <Divider label={"Distribution"} my={"md"} orientation={"horizontal"} variant={"solid"} size={"xs"} />
                </Grid.Col>
                <Grid.Col span={1}>
                <Button onClick={()=>{openNewRoleModal(2)}}>+</Button>
                </Grid.Col>
            </Grid>
            

            <Grid align={"center"}>
            {towerRoles && towerRoles.map((item)=>{
                if(item.roleType === 2){
                    return(
                        
                            <Grid.Col md={6} lg={3}>
                                <RoleCard  roleName={item.roleName} roleIp={item.roleIp} roleType={item.roleType}/>
                            </Grid.Col>
                        
                    )
                }
                
            })}
            </Grid>



            <Grid align="center">
                <Grid.Col span={11}>
                    <Divider label={"Access"} my={"md"} orientation={"horizontal"} variant={"solid"} size={"xs"} />
                </Grid.Col>
                <Grid.Col span={1}>
                    <Button onClick={()=>{openNewRoleModal(3)}}>+</Button>
                </Grid.Col>
            </Grid>

            <Grid align={"center"}>
                {towerRoles && towerRoles.map((item)=>{
                    if(item.roleType === 3){
                        return(
                            
                                <Grid.Col md={6} lg={3}>
                                    <RoleCard roleName={item.roleName} roleIp={item.roleIp} roleType={item.roleType} onClick={handleOpenApDetailModal} roleId={item.roleId}/>
                                </Grid.Col>
                            
                        )
                    }
                    
                })}
            </Grid>
            


            </Skeleton>


            <Modal bg={"gray"} title={"New Device Role"} opened={addDeviceModalVisible} onClose={()=>{closeNewRoleModal()}}>
                <Stack>
                    <Select value={addRoleType} onChange={setAddRoleType}  data={[
                        {value: 1, label: "Core"},
                        {value: 2, label: "Distribution"},
                        {value: 3, label: "Access"}
                    ]}/>
                    <TextInput value={addRoleName} onChange={(e)=>{setAddRoleName(e.target.value)}} label={"Network Role Name"} placeholder="Tower.Router.Core" />
                    <TextInput value={addRoleIp} onChange={(e)=>{setAddRoleIp(e.target.value)}} label={"Discovery IP Address"} placeholder="x.x.x.x" />
                    <Button disabled={addDeviceRoleLoading} onClick={() =>{ handleSubmitNewRole()}}>Save Changes</Button>
                </Stack>

            </Modal>

            <Modal opened={viewApDetail} onClose={()=>{setViewApDetail(false)}}>
                <ApDetail apId={viewApId} apName={viewApName} apIp={viewApIp} closeModal={handleCloseApDetailModal}/>
            </Modal>



        </>
    )

}